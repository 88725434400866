import React from 'react';
import clsx  from 'clsx';
import PropTypes from 'prop-types';
import { PlanTypes } from '../../config/plan-types';
import { CURRENCY } from '../../config/main';
import { useStyles } from './AbandonmentModalV2Styles';
import { withNamespaces, Trans } from 'react-i18next';

import iconWindows from '../../assets/images/icon-windows-black.png';
import iconAndroid from '../../assets/images/icon-android-black.png';
import iconMacOS from '../../assets/images/icon-mac-os-black.png';
import iconIos from '../../assets/images/icon-ios-black.png';
import iconFireTV from '../../assets/images/icon-fire-tv-black.png';
import iconAndroidTV from '../../assets/images/icon-androidtv-black.png';
import abandonmentImg from '../../assets/images/abandonment_2.png';


const AbandonmentModalV2 = ({
	t,
	showModal,
	showYearly2,
	couponValidPlan,
	couponPrice,
	couponGracePeriod,
	couponSavingPercent,
	savingPercent,
	saving2Percent,
	yearlyPackage,
	yearly2Package,
	currency,
	onClose,
	customTitle
}) => {
	const calculatePricePerMonth = (amount, amount2, grace2Period) => {
		let _pricePerMonth = 0;

		if(showYearly2) {
			_pricePerMonth = (couponPrice && couponValidPlan === PlanTypes.YEARLY2) ? couponPrice/(couponGracePeriod || 24) : amount2/(grace2Period || 24);
		} else {
			_pricePerMonth = (couponPrice && couponValidPlan === PlanTypes.YEARLY) ? couponPrice/(couponGracePeriod || 12) : amount/12;
		}

		return (Math.round(_pricePerMonth*100)/100).toFixed(2);
	};

	const classes = useStyles();
	const _basePrice = ((yearlyPackage?.recurringPeriod?.basePrice) || []).find(b => b.currencyCode === currency);
	const _amount = _basePrice?.amount || 0;
	const _basePrice2 = ((yearly2Package?.recurringPeriod?.basePrice) || []).find(b => b.currencyCode === currency);
	const _grace2Period = yearly2Package.recurringPeriod?.grace_period_months || 0;
	const _amount2 = _basePrice2?.amount || 0;
	const transGetPrivadoVPNFor = (CURRENCY[currency] || currency) + calculatePricePerMonth(_amount, _amount2, _grace2Period);
	const transLockInSavings = (showYearly2 ? saving2Percent : savingPercent) || couponSavingPercent;

	return (
		<div className={classes.abModal} style={{display: (showModal ? 'flex' : 'none')}} onClick={onClose}>
			<div id="ca-popup-paid" className={classes.abModalWrapper} onClick={e => onClose(e, (showYearly2 ? yearly2Package : yearlyPackage))}>
				<div className={classes.abModalCloseBtn} onClick={onClose}>&times;</div>
				<div className={classes.abModalBody}>
					<div className={classes.abModalContent}>
						<div className={classes.abModalDesc}>{t('Fast and Secure VPN You Can Trust')}</div>
						<div className={classes.abModalTitle}>
							{t(customTitle) ||
							(!couponPrice || couponPrice && couponGracePeriod > 1 ? 
								<Trans i18nKey="transGetPrivadoVPNFor" transGetPrivadoVPNFor={transGetPrivadoVPNFor}>
									Get PrivadoVPN for {{transGetPrivadoVPNFor}}/mo
								</Trans>:
								<Trans i18nKey="transGetPrivadoVPNFor1" transGetPrivadoVPNFor={transGetPrivadoVPNFor}>
									Get PrivadoVPN for {{transGetPrivadoVPNFor}}
								</Trans>)
							}
						</div>
						<div className={classes.abModalMoneyBack}>{t('30-Day Money Back Guarantee')}</div>
						<div className={classes.abModalFeatures}>
							<div className={classes.abModalFeatureItem}>
								<Trans i18nKey="transLockInSavings" transLockInSavings={transLockInSavings}>
									Lock-in {{transLockInSavings}} Savings
								</Trans>
							</div>
							<div className={classes.abModalFeatureItem}>
								{t('Protected By Swiss Privacy Laws')}
							</div>
							<div className={classes.abModalFeatureItem}>
								{t('Advanced Wireguard® Encryption')}
							</div>
							<div className={classes.abModalFeatureItem}>
								{t('Protect Your Data On All Devices')}
							</div>
							<div className={clsx(classes.abModalFeatureItem, classes.abModalFeatureItemApps)}>
								<span>{t('Apps for')}</span>
								<img src={iconWindows} width="24" />
								<img src={iconAndroid} width="19" />
								<img src={iconMacOS} width="19" />
								<img src={iconIos} width="23" />
								<img src={iconFireTV} width="8" />
								<img src={iconAndroidTV} width="22" />
							</div>
						</div>
						<div className={classes.abModalBtn}>
							<span>{t('Get PrivadoVPN')}</span>
						</div>
					</div>
				</div>
				<img src={abandonmentImg} alt="" className={classes.abModalImg} />
			</div>
		</div>
	);
};

AbandonmentModalV2.propTypes = {
	showModal: PropTypes.bool,
	showYearly2: PropTypes.bool,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	freePackage: PropTypes.object,
	yearlyPackage: PropTypes.object,
	yearly2Package: PropTypes.object,
	currency: PropTypes.string,
	onClose: PropTypes.func,
	customTitle: PropTypes.string,
	t: PropTypes.func
};

export default withNamespaces()(AbandonmentModalV2);