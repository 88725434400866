export const META = [
	'utm_campaign',
	'utm_campaignid',
	'utm_adgroupid',
	'utm_adgroup',
	'utm_medium',
	'utm_source',
	'utm_content',
	'utm_adtype',
	'utm_term',
	'a_aid',
	'a_bid',
	'chan',
	'd1',
	'd2',
	'gclid'
];

export const LANGUAGES = [
	{lang: 'en', name: 'English'},
	{lang: 'de', name: 'Deutsch'},
	{lang: 'nl', name: 'Nederlands'},
	{lang: 'fr', name: 'Français'},
	{lang: 'hu', name: 'Magyar'},
	{lang: 'el', name: 'Ελληνικά'},
	{lang: 'id', name: 'Bahasa Indonesia'},
	{lang: 'ru', name: 'Русский'},
	{lang: 'tr', name: 'Türkçe'},
	{lang: 'pl', name: 'Polski'},
	{lang: 'pt', name: 'Português'},
	{lang: 'hr', name: 'Hrvatski'},
	{lang: 'es', name: 'Español'},
	{lang: 'it', name: 'Italiano'},
	{lang: 'zh-cn', name: '简体中文'},
	{lang: 'zh-tc', name: '繁體中文'}
];

export const CURRENCY = {
	'USD': '$',
	'EUR': '€',
	'GBP': '£',
	'CHF': 'Fr'
};

export const EURO_COUNTRY_CODES = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];

export const EMAIL_REGEXP = /^\w+([.-]?[!#$%&'*+=?^_`{|}~\w])*?@(\w[.-]?)+(\w+\.)+\w{2,4}$/;

export const LETTERS_REGEXP = /^[A-Za-z0-9.-]*$/;

export const DATE_REGEXP = /^[0-9]{2}\/[0-9]{2}?$/;

export const REDIRECT_URLS = {
	'special-offer': [
		'/black-friday', '/black-friday-offer', '/black-friday-deal'
	],
	'limitedoffer': [
		'/best-offer', '/get-privadovpn', '/bestvpnoffer', '/bestvpn', 
		'/get-deal', '/great-deal', '/order', '/premium-recommended', 
		'/limitedoffer-b', '/limitedvpnoffer-b', '/signupnow', '/getprivado', 
		'/get-unlimited-vpn', '/chipde', '/computerbild', '/netzweltde', '/netzwelt', 
		'/best-free-vpn-b', '/great-deal-b', '/special-deal-b', 
		'/special-offer-20', '/special-offer-30', '/special-offer-intro-20', 
		'/student-discount', '/special-deal', '/getvpnaccount', '/vpn-offer'
	],
	'offer': [
		'/special-deal-free', '/offer-free', '/spyoff-special-offer-intro-30', 
		'/great-deal-trial', '/get-deal-trial', '/bestvpntrial'
	]
};