import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import customService from '../../../services/custom';
import Loader from '../../UI/Loader/Loader';
import PaymentInfoV3 from '../PaymentInfoV3/PaymentInfoV3';
import { withNamespaces } from 'react-i18next';
import clsx  from 'clsx';
import utils from '../../../services/utils';
import { PlanTypes } from '../../../config/plan-types';
import {
	Container,
	Grid,
	TextField,
	Typography,
	Box
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FormsV3Styles';

const { REACT_APP_CREDIT_CARD, REACT_APP_CREDIT_CARD_TOKEN } = process.env;

function CreditCardFormV3({
	t,
	mbg,
	isApplicableInTheCountry,
	upgradeMode,
	planConfig,
	savingPercent,
	saving2Percent,
	classes,
	saving,
	currency,
	selectedPackage,
	paymentJS,
	formFields,
	paymentFields,
	withPassword,
	fieldsError,
	fieldsTouched,
	btnDisabled,
	btnProcessing,
	couponCode,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponSavingPercent,
	couponRenewalPrice,
	couponMonthly,
	couponYearly,
	couponYearly2,
	isProxyDetected,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onChangeFormFields,
	onBlur,
	onInit,
	onSubmit,
	selectedPaymentMethod
}) {
	const [loading, handleLoading] = useState(true);
	const [iframesInfo, iframesInfoUpdate] = useState({});
	const [iFrameError, handleIframeError] = useState(false);

	useEffect(() => {
		if(!iframesInfo.ccNumberTouched || (iframesInfo.ccNumberTouched && (!iframesInfo.validNumber || iframesInfo.validNumber === false)) ||
			!iframesInfo.cvvTouched || (iframesInfo.cvvTouched && (!iframesInfo.validCvv || iframesInfo.validCvv === false))) {
			handleIframeError(true);
		} else {
			handleIframeError(false);
		}
	}, [iframesInfo, handleIframeError]);

	useEffect(() => {
		if(paymentJS) {
			const iframeSubscription = customService.initPayment(paymentJS, REACT_APP_CREDIT_CARD_TOKEN, 'v2').subscribe(data => {
				handleLoading(false);
				if(data) {
					let _iframesInfo = {};
					if(data.ccNumber){
						_iframesInfo = data.ccNumber;
					} else if(data.cvv){
						_iframesInfo = data.cvv;
					}
					iframesInfoUpdate(prevItems => ({...prevItems, ..._iframesInfo}));
				}

			});
			return () => {
				iframeSubscription.unsubscribe();
			};
		}
	}, [paymentJS]);

	useEffect(() => {
		if(!paymentFields[selectedPaymentMethod] && selectedPaymentMethod === REACT_APP_CREDIT_CARD) {
			onInit({
				[selectedPaymentMethod] : {
					firstName: '',
					lastName: '',
					expirationMonthYear: '',
					address: '',
					city: '',
					state: '',
					zipCode: '',
					termsAndConditions: false
				}
			});
			iframesInfoUpdate({});
		}
	}, [onInit, paymentFields, selectedPaymentMethod]);

	const _pf = paymentFields[selectedPaymentMethod] || {};
	const _pft = fieldsTouched[selectedPaymentMethod] || {};
	const _pfe = fieldsError[selectedPaymentMethod] || {};
	const _ff = {...formFields} || {};
	const _ft = {...fieldsTouched['formFields']} || {};
	const _fe = {...fieldsError['formFields']} || {};

	const ccNumberError = iframesInfo.ccNumberTouched && (iframesInfo.numberErrorMessage || iframesInfo.numberLength === 0 || iframesInfo.validNumber === false ||
		(((iframesInfo.cardType === 'amex' && iframesInfo.numberLength < 15) || (iframesInfo.cardType !== 'amex' && iframesInfo.cardType !== 'diners' && iframesInfo.numberLength < 16)) && iframesInfo.ccNumberBlurred) ||
		(((iframesInfo.cardType === 'amex' && iframesInfo.numberLength === 15) || (iframesInfo.cardType !== 'amex' && iframesInfo.numberLength === 16)) && (iframesInfo.validNumber === false)));
	const cvvError = iframesInfo.cvvTouched && (iframesInfo.cvvLength === 0 ||
		(((iframesInfo.cardType === 'amex' && iframesInfo.cvvLength < 4) || (iframesInfo.cardType !== 'amex' && iframesInfo.cvvLength < 3)) && iframesInfo.cvvBlurred) ||
		(((iframesInfo.cardType === 'amex' && iframesInfo.cvvLength === 4) || (iframesInfo.cardType !== 'amex' && iframesInfo.cvvLength === 3)) && (iframesInfo.validCvv === false)));

	if(!withPassword) {
		delete _ff.password;
		delete _ft.password;
		delete _fe.password;
	}

	const _couponByPlan = utils.executeCouponByPlan(selectedPackage, PlanTypes, couponYearly, couponYearly2, couponMonthly);
	const _paymentNotEligible = _couponByPlan.code && utils.checkPaymentEligibility(_couponByPlan.payment_methods, REACT_APP_CREDIT_CARD);
	const disabled = (Object.keys(_pfe).length + Object.keys(_fe).filter(i => i !== 'termsAndConditions').length > 0) || (Object.keys(_ff).filter(i => i !== 'termsAndConditions').length + Object.keys(_pf).length !== Object.keys(_pft).length + Object.keys(_ft).filter(i => i !== 'termsAndConditions').length) || (_pf['expirationMonthYear'] && _pf['expirationMonthYear'].length < 5) || iFrameError || btnDisabled || btnProcessing || isProxyDetected || _paymentNotEligible;

	return (
		<Container component="main">
			<Box pb={2}>
				{loading ?
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Loader />
						</Grid>
					</Grid> : null
				}
				<Grid container spacing={2} className={loading ? classes.hide : null}>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="firstName"
							label={t('First Name')}
							placeholder={t('Enter first name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="firstName"
							classes={{root: classes.fieldContainer}}
							value={_pf.firstName || ''}
							error={!!_pfe.firstName}
							helperText={(_pfe.firstName) ? <span className={classes.errorIcon}>{_pfe.firstName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'firstName')}
							onBlur={() => onBlur('firstName', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="lastName"
							label={t('Last Name')}
							placeholder={t('Enter last name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="lastName"
							classes={{root: classes.fieldContainer}}
							value={_pf.lastName || ''}
							error={!!_pfe.lastName}
							helperText={(_pfe.lastName) ? <span className={classes.errorIcon}>{_pfe.lastName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'lastName')}
							onBlur={() => onBlur('lastName', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={4} >
						<div className={classes.numberContainer}>
							<label className={classes.iframeLabel}>{t('Card Number')}</label>
							<div id="number_div" className={clsx({[classes.numberWrapper]: true, [classes.errorField]: ccNumberError})}></div>
							{ccNumberError ?
								<Typography component="p" className={classes.errorText}>
									<span className={classes.errorIcon}>{t((iframesInfo.numberLength === 0) ? 'Please Enter Card Number' : (iframesInfo.numberErrorMessage || 'Please Enter Valid Card Number'))}</span>
								</Typography> : null
							}
						</div>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							variant="outlined"
							fullWidth
							id="expirationMonthYear"
							label={t('Expiration Date')}
							placeholder="MM/YY"
							InputLabelProps={{
								shrink: true,
							}}
							name="expirationMonthYear"
							classes={{root: classes.fieldContainer}}
							value={_pf.expirationMonthYear || ''}
							error={!!_pfe.expirationMonthYear}
							helperText={(_pfe.expirationMonthYear) ? <span className={classes.errorIcon}>{_pfe.expirationMonthYear}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'expirationMonthYear')}
							onKeyUp={e => onChangeFormFields(e.target.value, 'expirationMonthYear', e)}
							onBlur={() => onBlur('expirationMonthYear', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<div className={classes.cvvContainer}>
							<div className={classes.cvvInfoIcon}>
								<div className={classes.cvvInfo}>
									<span className={classes.cvvInfoImg}></span>
									<span>{t('The CVV/CVC code is a three-digit code that appears on the back of your credit card. For American Express, the CVV/CVC code is a four-digit code on the front of the card.')}</span>
								</div>
							</div>
							<label className={classes.iframeLabel}>{t('CVC')}</label>
							<div id="cvv_div" className={clsx({[classes.cvvWrapper]: true, [classes.errorField]: cvvError})}></div>
							{cvvError ?
								<Typography component="p" className={classes.errorText}>
									<span className={classes.errorIcon}>{t((iframesInfo.cvvLength === 0) ? 'Please Enter Security Code' : 'Security Code is not Valid')}</span>
								</Typography> : null
							}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('Address')}
							placeholder={t('Enter address')}
							InputLabelProps={{
								shrink: true,
							}}
							name="address"
							classes={{root: classes.fieldContainer}}
							value={_pf.address || ''}
							error={!!_pfe.address}
							helperText={(_pfe.address) ? <span className={classes.errorIcon}>{_pfe.address}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'address')}
							onBlur={() => onBlur('address', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('City')}
							placeholder={t('Enter city')}
							InputLabelProps={{
								shrink: true,
							}}
							name="city"
							classes={{root: classes.fieldContainer}}
							value={_pf.city || ''}
							error={!!_pfe.city}
							helperText={(_pfe.city) ? <span className={classes.errorIcon}>{_pfe.city}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'city')}
							onBlur={() => onBlur('city', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="state"
							label={t('State/Province')}
							placeholder={t('Enter state/province')}
							InputLabelProps={{
								shrink: true,
							}}
							name="state"
							classes={{root: classes.fieldContainer}}
							value={_pf.state || ''}
							error={!!_pfe.state}
							helperText={(_pfe.state) ? <span className={classes.errorIcon}>{_pfe.state}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'state')}
							onBlur={() => onBlur('state', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="zipCode"
							label={t('ZIP/Postal Code')}
							placeholder={t('Enter ZIP/Postal code')}
							InputLabelProps={{
								shrink: true,
							}}
							name="zipCode"
							classes={{root: classes.fieldContainer}}
							value={_pf.zipCode || ''}
							error={!!_pfe.zipCode}
							helperText={(_pfe.zipCode) ? <span className={classes.errorIcon}>{_pfe.zipCode}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'zipCode')}
							onBlur={() => onBlur('zipCode', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} style={{display: (isApplicableInTheCountry ? 'none' : 'block')}}>
						<div className={classes.countryWarning}>
							{t('Based on your current location, the selected payment method may not be available.')}
						</div>
					</Grid>
					<Grid item xs={12}>
						<PaymentInfoV3
							_pf={_pf}
							_pft={_pft}
							_pfe={_pfe}
							paymentNotEligible={_paymentNotEligible}
							paymentDisplayName="Credit Card"
							saving={saving}
							savingPercent={savingPercent}
							saving2Percent={saving2Percent}
							selectedPackage={selectedPackage}
							currency={currency}
							couponByPlan={_couponByPlan}
							couponCode={couponCode}
							couponPrice={couponPrice}
							couponGracePeriod={couponGracePeriod}
							couponValidPlan={couponValidPlan}
							couponSavingPercent={couponSavingPercent}
							couponRenewalPrice={couponRenewalPrice}
							upgradeMode={upgradeMode}
							planConfig={planConfig}
							disabled={disabled}
							isProxyDetected={isProxyDetected}
							mbg={mbg}
							btnProcessing={btnProcessing}
							amexWarning={iframesInfo?.cardType === 'amex' && currency === 'EUR'}
							onChangePaymentMethod={onChangePaymentMethod}
							onChangeCouponCodeField={onChangeCouponCodeField}
							onChangeFormFields={onChangeFormFields}
							onSubmit={onSubmit}
						/>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}

CreditCardFormV3.propTypes = {
	mbg: PropTypes.bool,
	isApplicableInTheCountry: PropTypes.bool,
	upgradeMode: PropTypes.bool,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	currency: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.number,
	selectedPackage: PropTypes.object,
	paymentJS: PropTypes.object,
	formFields: PropTypes.object,
	paymentFields: PropTypes.object,
	withPassword: PropTypes.bool,
	fieldsError: PropTypes.object,
	fieldsTouched: PropTypes.object,
	btnDisabled: PropTypes.bool,
	btnProcessing: PropTypes.bool,
	selectedPaymentMethod: PropTypes.string,
	couponYearly: PropTypes.object,
	couponCode: PropTypes.string,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponMonthly: PropTypes.object,
	couponYearly2: PropTypes.object,
	isProxyDetected: PropTypes.bool,
	onChangePaymentMethod: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onBlur: PropTypes.func,
	onInit: PropTypes.func,
	onSubmit: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(CreditCardFormV3));