export const useStyles = () => ({
	switcherContainer: {
		position: 'relative',
		minWidth: 100,
		zIndex: 150,
	},
	menuBtn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '0px 0px 0px 5px',
		cursor: 'pointer',
		color: '#ffffff',
		fontSize: 12,
		fontWeight: 600,
		'& svg': {
			minWidth: 18,
		},
		'& span': {
			marginTop: 3,
			marginLeft: 5,
		},
		'&:after': {
			content: '""',
			width: 9,
			height: 9,
			marginLeft: 5,
			background: 'url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 fill=%22%23fff%22 viewBox=%220 0 14 22%22%3E%3Cpath d=%22M0 3l8 8-8 8 3 3L14 11 3 0z%22/%3E%3C/svg%3E") no-repeat 50%',
			transform: 'rotate(90deg)',
		},
		'&$menuBtnActive': {
			'&:after': {
				transform: 'rotate(-90deg)',
			},
			'&+$dropdown': {
				display: 'block',
			},
		},
	},
	dropdown: {
		display: 'none',
		position: 'absolute',
		top: 60,
		right: '-10px',
		minWidth: 335,
		background: '#fafafa',
		borderRadius: 4,
		padding: '10px',
		border: '1px solid rgba(0,0,0,.15)',
	},
	languagesList: {
		columnCount: 2,
		'& li': {
			position: 'relative',
			color: '#212529',
			fontSize: 12,
			fontWeight: 600,
			cursor: 'pointer',
			padding: 5,
			display: 'flex',
			alignItems: 'center',
			'&:hover': {
				color: '#9aa4d3',
			},
			'& svg': {
				width: 16,
				height: 16,
				borderRadius: 8,
				marginRight: 5,
			}
		},
	},
	menuBtnActive: {

	},
	V3: {
		'&$switcherContainer': {
			'& $menuBtn': {
				color: '#283339',
				'&:after': {
					background: 'url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 fill=%22%23212529%22 viewBox=%220 0 14 22%22%3E%3Cpath d=%22M0 3l8 8-8 8 3 3L14 11 3 0z%22/%3E%3C/svg%3E") no-repeat 50%',
				}
			}
		}
	},
	'@media (max-width: 460px)': {
		dropdown: {
			minWidth: 290,
		},
		languagesList: {
			'& li': {
				display: 'inline-block',
			},
		},
	},
});