import React from 'react';
import { 
	Container,
	AppBar,
	Toolbar,
	Typography 
} from '@material-ui/core';
import clsx  from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './HeaderStyles';
import { LANGUAGES } from '../../config/main';

import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import privadovpnLogo from '../../assets/images/privadovpn-logo.png';
import privadovpnLogoDark from '../../assets/images/privadovpn-logo-dark.png';

const { REACT_APP_WEB, REACT_APP_URL } = process.env;

function Header({
	V3,
	logoUrl, 
	appUrl, 
	centered, 
	onChangeLanguage
}) {
	const classes = useStyles();
	const _privadovpnLogo = V3 ? privadovpnLogoDark : privadovpnLogo;
	const _logoUrl = logoUrl ? (REACT_APP_WEB + logoUrl) : appUrl ? (REACT_APP_URL + appUrl) : '';

	return (
		<AppBar position="static" color="default" elevation={0} className={clsx(classes.appBar, {[classes.V3]: V3})}>
			<Container maxWidth={V3 ? 'xl' : 'lg'} component="main">
				<Toolbar className={classes.toolbar}>
					<Typography variant="h6" color="inherit" noWrap className={clsx(classes.toolbarTitle, {[classes.logoCenter]: centered})}>
						{_logoUrl ?
							<a color="textPrimary" href={_logoUrl} rel="noopener noreferrer" className={classes.logoLink}>
								<img src={_privadovpnLogo} alt="logo" className={classes.logo} />
							</a> :
							<div className={classes.logoLink}>
								<img src={_privadovpnLogo} alt="logo" className={classes.logo} />
							</div>
						}
					</Typography>
					{LANGUAGES.length ?
						<LanguageSwitcher
							V3={V3}
							languages={LANGUAGES}
							onChangeLanguage={onChangeLanguage}
						/> : null
					}
				</Toolbar>
			</Container>
		</AppBar>
	);
}

Header.propTypes = {
	V3: PropTypes.bool,
	centered: PropTypes.bool,
	logoUrl: PropTypes.string,
	appUrl: PropTypes.string,
	onChangeLanguage: PropTypes.func
};

export default Header;