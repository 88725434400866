import React from 'react';
import clsx  from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { PlanTypes } from '../../../config/plan-types';
import { CURRENCY } from '../../../config/main';


function FeaturesBoxV23({
	selectedPackage = {}, 
	classes, 
	t, 
	currency,
	couponGracePeriod,
	couponValidPlan,
	couponPrice,
}) {

	const _currency = CURRENCY[currency] || currency;
	const _basePrice = ((selectedPackage?.recurringPeriod?.basePrice) || []).find(b => b.currencyCode === currency);
	const _gracePeriod = selectedPackage?.recurringPeriod?.grace_period_months || 0;
	const _amount = _basePrice?.amount || 0;

	return (
		<Box className={classes.paymentInfoWrapper}>
			<Box display="flex" justifyContent="space-between" className={classes.paymentInfoBlock}>
				<Box>{t('PrivadoVPN')}</Box>
				<Box fontWeight={700}>
					{_currency + (selectedPackage.packageIdentifier === PlanTypes.YEARLY ?
						(couponValidPlan === PlanTypes.YEARLY && couponPrice ? (couponGracePeriod ? +couponPrice : Math.round((couponPrice/12)*100)/100) : Math.round((_amount/12)*100)/100) :
						selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ?
							(couponValidPlan === PlanTypes.YEARLY2 && couponPrice ? (couponGracePeriod ? +couponPrice : Math.round((couponPrice/24)*100)/100) : Math.round((_amount/(_gracePeriod || 24))*100)/100) :
							selectedPackage.packageIdentifier === PlanTypes.MONTHLY ? (couponValidPlan === PlanTypes.MONTHLY && couponPrice ? +couponPrice : _amount) : _amount).toFixed(2)
					}
				</Box>
			</Box>
			
			<Box display="flex" justifyContent="space-between" className={clsx(classes.paymentInfoBlock, classes.total)}>
				<Box fontWeight={700}>{t('Total')}:</Box>
				<Box fontWeight={700}>
					{_currency + ((selectedPackage.packageIdentifier === couponValidPlan) ? (+couponPrice || _amount) : _amount).toFixed(2)}
				</Box>
			</Box>
			{selectedPackage.packageIdentifier === PlanTypes.YEARLY || selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ?
				<>
					<Box display="flex" justifyContent="flex-end" className={classes.paymentInfoBlock}>
						<Box fontWeight={700} className={classes.perMonth}>
							{'(' + _currency + ((selectedPackage.packageIdentifier === PlanTypes.YEARLY ?
								(couponValidPlan === PlanTypes.YEARLY && couponPrice ? (couponGracePeriod ? +couponPrice : Math.round((couponPrice/12)*100)/100) : Math.round((_amount/12)*100)/100) :
								selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ?
									(couponValidPlan === PlanTypes.YEARLY2 && couponPrice ? (couponGracePeriod ? +couponPrice : Math.round((couponPrice/24)*100)/100) : Math.round((_amount/(_gracePeriod || 24))*100)/100) :
									selectedPackage.packageIdentifier === PlanTypes.MONTHLY ? (couponValidPlan === PlanTypes.MONTHLY && couponPrice ? +couponPrice : _amount) : _amount)).toFixed(2) + t('/mo') + ')'
							}
						</Box>
					</Box>
				</>:null
			}
		</Box>
	);
}

const mapStateToProps = (state) => {
	return {
		serversDetails: state.home.serversDetails
	};
};

FeaturesBoxV23.propTypes = {
	selectedPackage: PropTypes.object,
	serversDetails: PropTypes.object,
	classes: PropTypes.object,
	planConfig: PropTypes.object,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	currency: PropTypes.string,
	t: PropTypes.func,

};

export default connect(mapStateToProps, null)(withNamespaces()(FeaturesBoxV23));