import React, { useEffect } from 'react';
import {
	Grid,
	Container,
	TextField,
	Typography,
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import LoaderSmall from '../../UI/LoaderSmall/LoaderSmall';
import PaymentInfoV2 from '../PaymentInfoV2/PaymentInfoV2';
import { PlanTypes } from '../../../config/plan-types';
import { withNamespaces } from 'react-i18next';
import utils from '../../../services/utils';
import clsx from 'clsx';
import MBG from '../../UI/MBG/MBG';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FormsV2Styles';

const { REACT_APP_IDEAL } = process.env;

function IDealFormV2({
	t,
	mbg,
	isApplicableInTheCountry,
	upgradeMode,
	planConfig,
	savingPercent,
	saving2Percent,
	classes,
	saving,
	currency,
	selectedPackage,
	formFields,
	paymentFields,
	withPassword,
	fieldsError,
	fieldsTouched,
	btnDisabled,
	btnProcessing,
	isCouponCodeFieldOpened,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponSavingPercent,
	couponRenewalPrice,
	couponCode,
	couponYearly2,
	couponYearly,
	couponMonthly,
	isProxyDetected,
	iDealBankList,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onSubmitCouponCode,
	onChangeFormFields,
	onBlur,
	onInit,
	onSubmit,
	onOpenCouponCodeField,
	selectedPaymentMethod
}) {

	useEffect(() => {
		if(!paymentFields[selectedPaymentMethod] && selectedPaymentMethod === REACT_APP_IDEAL) {
			onInit({
				[selectedPaymentMethod] : {
					firstName: '',
					lastName: '',
					bic: '',
					termsAndConditions: false
				}
			});
		}
	}, [onInit, paymentFields, selectedPaymentMethod]);

	const _pf = paymentFields[selectedPaymentMethod] || {};
	const _pft = fieldsTouched[selectedPaymentMethod] || {};
	const _pfe = fieldsError[selectedPaymentMethod] || {};
	const _ff = {...formFields} || {};
	const _ft = {...fieldsTouched['formFields']} || {};
	const _fe = {...fieldsError['formFields']} || {};

	if(!withPassword) {
		delete _ff.password;
		delete _ft.password;
		delete _fe.password;
	}
	
	const _couponByPlan = utils.executeCouponByPlan(selectedPackage, PlanTypes, couponYearly, couponYearly2, couponMonthly);
	const _paymentNotEligible = _couponByPlan?.code && utils.checkPaymentEligibility(_couponByPlan?.payment_methods, REACT_APP_IDEAL);
	const disabled = (Object.keys(_pfe).length + Object.keys(_fe).length > 0) || (Object.keys(_ff).length + Object.keys(_pf).length !== Object.keys(_pft).length + Object.keys(_ft).length) || !_pf.termsAndConditions || btnDisabled || btnProcessing || isProxyDetected || _paymentNotEligible;

	return (
		<>
			<Container maxWidth="sm" component="main">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('First Name')}
							placeholder={t('Enter first name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="firstName"
							classes={{root: classes.fieldContainer}}
							value={_pf.firstName || ''}
							error={!!_pfe.firstName}
							helperText={(_pfe.firstName) ? <span className={classes.errorIcon}>{_pfe.firstName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'firstName')}
							onBlur={() => onBlur('firstName', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('Last Name')}
							placeholder={t('Enter last name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="lastName"
							classes={{root: classes.fieldContainer}}
							value={_pf.lastName || ''}
							error={!!_pfe.lastName}
							helperText={(_pfe.lastName) ? <span className={classes.errorIcon}>{_pfe.lastName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'lastName')}
							onBlur={() => onBlur('lastName', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" classes={{root: classes.fieldContainer}} fullWidth error={!!_pfe.bic}>
							<InputLabel shrink>{t('Select BIC')}</InputLabel>
							<Select
								value={_pf.bic || ''}
								onChange={e => onChangeFormFields(e.target.value, 'bic')}
								onClose={() => onBlur('bic', selectedPaymentMethod)}
								label={t('Select BIC')}
								displayEmpty
								renderValue={
									_pf.bic !== '' ? undefined : () => <div className={classes.placeholder}>{t('Select BIC')}</div>
								}
							>
								{iDealBankList.map(c => (
									<MenuItem
										key={c.bic}
										className={clsx(classes.countrySelectItem, {[classes.countrySelectItemActive]: c.value === formFields.country})}
										value={c.bic}>{c.name}</MenuItem>
								))}
							</Select>

							<FormHelperText>{(_pfe.bic) ? <span className={classes.errorIcon}>{_pfe.bic}</span> : ''}</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} style={{display: (isApplicableInTheCountry ? 'none' : 'block')}}>
						<div className={classes.countryWarning}>
							{t('Based on your current location, the selected payment method may not be available.')}
						</div>
					</Grid>
				</Grid>
			</Container>

			<div className={classes.separator}></div>

			<Container maxWidth="sm" component="main">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PaymentInfoV2
							_pf={_pf}
							_pft={_pft}
							_pfe={_pfe}
							paymentNotEligible={_paymentNotEligible}
							paymentDisplayName="iDeal"
							couponByPlan={_couponByPlan}
							saving={saving}
							savingPercent={savingPercent}
							saving2Percent={saving2Percent}
							selectedPackage={selectedPackage}
							currency={currency}
							customClassName="paymentInfoPayPalContainerV2"
							isCouponCodeFieldOpened={isCouponCodeFieldOpened}
							couponPrice={couponPrice}
							couponGracePeriod={couponGracePeriod}
							couponValidPlan={couponValidPlan}
							couponSavingPercent={couponSavingPercent}
							couponRenewalPrice={couponRenewalPrice}
							couponCode={couponCode}
							onChangePaymentMethod={onChangePaymentMethod}
							onOpenCouponCodeField={onOpenCouponCodeField}
							onChangeCouponCodeField={onChangeCouponCodeField}
							onSubmitCouponCode={onSubmitCouponCode}
							onChangeFormFields={onChangeFormFields}
							planConfig={planConfig}
						/>
					</Grid>
				</Grid>

				<Typography component="p" className={classes.info}>
					{selectedPackage.packageIdentifier == PlanTypes.YEARLY2 ? t('I authorize Privado Networks AG (https://privadovpn.com/) to send instructions to my bank to debit my account every 24 months.') : selectedPackage.packageIdentifier == PlanTypes.MONTHLY ? t('I authorize Privado Networks AG (https://privadovpn.com/) to send instructions to my bank to debit my account every 1 month.') : t('I authorize Privado Networks AG (https://privadovpn.com/) to send instructions to my bank to debit my account every 12 months.')} {t('At the same time I authorize my bank to debit my account in accordance with the instructions from Privado Networks AG.')}
				</Typography>

				<div className={classes.submitContainer}>
					<div>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={disabled}
							onClick={onSubmit}
						>
							{btnProcessing ? <LoaderSmall /> : null}
							{t(btnProcessing ? 'Processing' : upgradeMode ? 'Confirm Purchase' : 'Get Now')}

						</Button>
						{isProxyDetected ?
							<div className={classes.errorIcon}>{t('We detected you are using a proxy, please disconnect your proxy before placing an order.')}</div>: null
						}
					</div>
					{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
						<div className={classes.moneyBackLg}>
							<div className={classes.moneyBackLgBadge}>
								<MBG />
							</div>
							<div className={classes.moneyBackLgTitle}>{t('30-Day Money Back Guarantee')}</div>
							<div className={classes.moneyBackLgContent}>{t('Try PrivadoVPN Premium for 30 days, risk-free. If you aren\'t entirely satisfied, we will give you a full refund.')}</div>
						</div>: null
					}
				</div>
				{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
					<div className={classes.moneyBackSm}>
						<div className={classes.moneyBackContentSm}>
							{t('30-Day Money Back Guarantee')}
						</div>
					</div> : null
				}
			</Container>
		</>
	);
}

IDealFormV2.propTypes = {
	mbg: PropTypes.bool,
	isApplicableInTheCountry: PropTypes.bool,
	upgradeMode: PropTypes.bool,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	currency: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.number,
	selectedPackage: PropTypes.object,
	formFields: PropTypes.object,
	paymentFields: PropTypes.object,
	withPassword: PropTypes.bool,
	fieldsError: PropTypes.object,
	fieldsTouched: PropTypes.object,
	btnDisabled: PropTypes.bool,
	btnProcessing: PropTypes.bool,
	selectedPaymentMethod: PropTypes.string,
	couponYearly: PropTypes.object,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponCode: PropTypes.string,
	couponYearly2: PropTypes.object,
	couponMonthly: PropTypes.object,
	isCouponCodeFieldOpened: PropTypes.bool,
	isProxyDetected: PropTypes.bool,
	iDealBankList: PropTypes.array,
	onChangePaymentMethod: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onSubmitCouponCode: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onBlur: PropTypes.func,
	onInit: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpenCouponCodeField: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(IDealFormV2));