import React, { useState, useEffect, useRef } from 'react';
import clsx  from 'clsx';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './LanguageSwitcherStyles';
import { Us, De, Nl, Fr, Hu, Gr, Id, Ru, Tr, Pl, Hr, Pt, Es, It, Hk, Cn } from '../UI/svg/languageIcons/flags';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const _languageIcons = {
	en: Us,
	de: De,
	nl: Nl,
	fr: Fr,
	hu: Hu,
	el: Gr,
	id: Id,
	ru: Ru,
	tr: Tr,
	pl: Pl,
	hr: Hr,
	pt: Pt,
	es: Es,
	it: It,
	'zh-tc': Hk,
	'zh-cn': Cn
};

function LanguageSwitcher({ V3, classes, i18n, languages, onChangeLanguage }) {
	const [isOpened, handleToggleMenu] = useState(false);
	const menuRef = useRef(null);
	const language = languages.filter(l => l.lang === i18n.resolvedLanguage)[0] || {};

	useEffect(() => {
		function hideMenu(event) {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				handleToggleMenu(false);
			}
		}

		document.addEventListener('mousedown', hideMenu);
		return () => {
			document.removeEventListener('mousedown', hideMenu);
		};
	}, [menuRef]);

	const toggleMenu = () => {
		handleToggleMenu(!isOpened);
	};

	const handleChangeLanguage = (l) => {
		cookies.set('userLang', l, { path: '/', domain: (window.location.hostname || '').split('signup.')[1], secure: true });
		onChangeLanguage(l);
		handleToggleMenu(false);
	};

	const renderIcon = lang => {
		const TagName = _languageIcons[lang];
		return _languageIcons[lang] ? <TagName /> : null;
	};

	return (
		<div className={clsx(classes.switcherContainer, {[classes.V3]: V3})} ref={menuRef}>
			<div className={clsx(classes.menuBtn, {[classes.menuBtnActive]: isOpened})} onClick={toggleMenu}>
				{V3 ? 
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12 2C10.0222 2 8.08881 2.58648 6.44431 3.6853C4.79982 4.78411 3.5181 6.34591 2.76122 8.17317C2.00435 10.0004 1.80629 12.0111 2.19215 13.9509C2.578 15.8907 3.5304 17.6726 4.92893 19.0711C6.32745 20.4696 8.10928 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.80429 19.0711 4.92892C17.1957 3.05356 14.6522 2 12 2ZM20.3 11.15H16.55C16.5224 8.73987 15.9723 6.36439 14.9375 4.1875C16.3878 4.73853 17.6569 5.68103 18.6036 6.9102C19.5503 8.13937 20.1376 9.60705 20.3 11.15ZM12 20.3375C10.875 20.3375 9.30002 17.4625 9.12502 12.8375H14.875C14.7 17.4625 13.125 20.3375 12 20.3375ZM9.12502 11.15C9.30002 6.53751 10.875 3.65001 12 3.65001C13.125 3.65001 14.7 6.52501 14.875 11.15H9.12502ZM9.12502 4.1875C8.09026 6.36439 7.5401 8.73987 7.51251 11.15H3.76251C3.92063 9.61269 4.50042 8.14893 5.43788 6.92031C6.37534 5.69169 7.63401 4.74597 9.07501 4.1875H9.12502ZM3.74999 12.85H7.49999C7.52759 15.2601 8.07775 17.6356 9.1125 19.8125C7.65282 19.269 6.37332 18.3298 5.41736 17.1C4.46139 15.8703 3.8667 14.3986 3.69998 12.85H3.74999ZM15 19.8125C16.0347 17.6356 16.5849 15.2601 16.6125 12.85H20.3625C20.1937 14.4015 19.5953 15.8751 18.6347 17.1051C17.6741 18.3351 16.3894 19.2728 14.925 19.8125H15Z" fill="#283339"/>
					</svg>:
					<svg fill="#bfc3ff" height="18" viewBox="0 0 18.66 18.66" width="18" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.33 18.66a9.33 9.33.0 119.33-9.33A9.35 9.35.0 019.33 18.66zm0-17A7.67 7.67.0 1017 9.33 7.68 7.68.0 009.33 1.66z"></path>
						<path d="M5.81 11a1.68 1.68.0 01-.5-.07A3 3 0 013.65 9.15a2.72 2.72.0 00-.46-.74c-.33-.24-1.06-.12-2 .33l-.7-1.5c1.56-.74 2.8-.8 3.67-.18a3.49 3.49.0 011 1.32c.17.34.44.85.63.91a1 1 0 00.61-.22 2.26 2.26.0 00.32-.22l-.23-.31A2.63 2.63.0 015.8 5.86 2.53 2.53.0 018 4.19c.66-.13.69-.15.73-.57a.3.3.0 00-.08-.29C8.49 3.18 7.76 2.7 4.78 3L4.63 1.33c2.72-.26 4.37.0 5.19.84a1.9 1.9.0 01.55 1.62 2.14 2.14.0 01-2.07 2c-.61.12-.81.18-1 .6s0 .59.41 1.1a2 2 0 01.62 1.61 2.18 2.18.0 01-1.14 1.37A2.72 2.72.0 015.81 11z"></path>
						<path d="M11.88 18.23a.81.81.0 01-.42-.12A.83.83.0 0111.17 17c1.17-2 1.34-2.86 1.34-3.22.0-.14-.34-.42-.61-.65a4.11 4.11.0 01-1.76-2.92 2 2 0 01.56-1.67c1-1.07 3-1 4.12-.91h.07a.63.63.0 000-.27c0-.07-.08-.13-.24-.17a2 2 0 00-1 .1 2.4 2.4.0 01-2.14-.2 1.29 1.29.0 01-.65-1c-.07-.71.26-1.7 3.07-4.16A.83.83.0 0115.05 2 .83.83.0 0115 3.15a10.77 10.77.0 00-2.44 2.61 1.06 1.06.0 00.62-.08A3.54 3.54.0 0115 5.56a2 2 0 011.6 2.19 1.56 1.56.0 01-1.42 1.54h-.39c-.58.0-2.35-.12-2.85.4a.43.43.0 00-.1.38A2.54 2.54.0 0013 11.84a2.54 2.54.0 011.2 1.92 8.93 8.93.0 01-1.57 4.07A.83.83.0 0111.88 18.23z"></path>
						<path d="M8.48 18.61a.81.81.0 01-.39-.1.82.82.0 01-.34-1.12 3.59 3.59.0 00.49-1.53l-.31-.12a2.32 2.32.0 01-1-.56A2 2 0 016.49 14c-.07-.54-.12-.76-.59-1s-1.14-.27-3 .48l-.85.36a.83.83.0 11-.67-1.52l.9-.32c1.87-.75 3.28-.9 4.29-.45a2.54 2.54.0 011.56 2.26 3.14 3.14.0 00.06.32s.17.0.29.06A1.74 1.74.0 019.9 15.81a5 5 0 01-.69 2.36A.82.82.0 018.48 18.61z"></path>
					</svg>
				} 
				<span>{language.name}</span>
			</div>
			<div className={classes.dropdown}>
				<ul className={classes.languagesList}>
					{languages.filter(l => l.lang !== i18n.resolvedLanguage).map((l, i) => {
						return (
							<li key={i} onClick={() => handleChangeLanguage(l.lang)}>
								{renderIcon(l.lang)}<span>{l.name}</span>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
}

LanguageSwitcher.propTypes = {
	V3: PropTypes.bool,
	i18n: PropTypes.object,
	classes: PropTypes.object,
	languages: PropTypes.array,
	onChangeLanguage: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(LanguageSwitcher));