import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	'@global': {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: 'none',
		},
	},
	appBar: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		background: '#151935',
	},
	toolbar: {

	},
	toolbarTitle: {
		flexGrow: 1,
	},
	logoCenter: {
		textAlign: 'center',
	},
	logoLink: {
		textDecoration: 'none',
		color: '#ddd',
		display: 'inline-block',
		margin: '15px 0 !important',
		padding: '0 !important',
		maxHeight: 49,
		'&:hover': {
			textDecoration: 'none',
		},
	},
	logo: {
		width: 250,
		maxWidth: '100%',
		border: 'none',
		verticalAlign: 'middle',
	},
	V3: {
		'&$appBar': {
			background: '#ffffff',
		},
	}
}));