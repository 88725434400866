import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PaymentInfoV3 from '../PaymentInfoV3/PaymentInfoV3';
import { withNamespaces } from 'react-i18next';
import utils from '../../../services/utils';
import { PlanTypes } from '../../../config/plan-types';
import {
	Grid,
	Container,
	Box
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FormsV3Styles';

const { REACT_APP_COINBASE } = process.env;

function CoinbaseV3({
	t,
	mbg,
	isApplicableInTheCountry,
	classes,
	upgradeMode,
	planConfig,
	savingPercent,
	saving2Percent,
	saving,
	currency,
	selectedPackage,
	formFields,
	paymentFields,
	withPassword,
	fieldsError,
	fieldsTouched,
	btnDisabled,
	btnProcessing,
	couponCode,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponSavingPercent,
	couponRenewalPrice,
	couponMonthly,
	couponYearly,
	couponYearly2,
	isProxyDetected,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onChangeFormFields,
	onInit,
	onSubmit,
	selectedPaymentMethod
}) {

	useEffect(() => {
		if(!paymentFields[selectedPaymentMethod] && (selectedPaymentMethod === REACT_APP_COINBASE)) {
			onInit({
				[selectedPaymentMethod]: {
					termsAndConditions: false
				}
			});
		}
	}, [onInit, paymentFields, selectedPaymentMethod]);

	const _pf = paymentFields[selectedPaymentMethod] || {};
	const _pft = fieldsTouched[selectedPaymentMethod] || {};
	const _pfe = fieldsError[selectedPaymentMethod] || {};
	const _ff = {...formFields} || {};
	const _ft = {...fieldsTouched['formFields']} || {};
	const _fe = {...fieldsError['formFields']} || {};

	if(!withPassword) {
		delete _ff.password;
		delete _ft.password;
		delete _fe.password;
	}

	const _couponByPlan = utils.executeCouponByPlan(selectedPackage, PlanTypes, couponYearly, couponYearly2, couponMonthly);
	const _paymentNotEligible = _couponByPlan.code && utils.checkPaymentEligibility(_couponByPlan.payment_methods, REACT_APP_COINBASE);
	const disabled = (Object.keys(_pfe).length + Object.keys(_fe).filter(i => i !== 'termsAndConditions').length > 0) || (Object.keys(_ff).filter(i => i !== 'termsAndConditions').length + Object.keys(_pf).length !== Object.keys(_pft).length + Object.keys(_ft).filter(i => i !== 'termsAndConditions').length) || btnDisabled || btnProcessing || isProxyDetected || _paymentNotEligible;

	return (
		<Container component="main">
			<Box pb={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} style={{display: (isApplicableInTheCountry ? 'none' : 'block')}}>
						<div className={classes.countryWarning}>
							{t('Based on your current location, the selected payment method may not be available.')}
						</div>
					</Grid>
					<Grid item xs={12}>
						<PaymentInfoV3
							_pf={_pf}
							_pft={_pft}
							_pfe={_pfe}
							paymentNotEligible={_paymentNotEligible}
							paymentDisplayName="Cryptocurrency"
							saving={saving}
							savingPercent={savingPercent}
							saving2Percent={saving2Percent}
							selectedPackage={selectedPackage}
							currency={currency}
							couponByPlan={_couponByPlan}
							couponCode={couponCode}
							couponPrice={couponPrice}
							couponGracePeriod={couponGracePeriod}
							couponValidPlan={couponValidPlan}
							couponSavingPercent={couponSavingPercent}
							couponRenewalPrice={couponRenewalPrice}
							upgradeMode={upgradeMode}
							planConfig={planConfig}
							disabled={disabled}
							isProxyDetected={isProxyDetected}
							mbg={mbg}
							btnProcessing={btnProcessing}
							onChangePaymentMethod={onChangePaymentMethod}
							onChangeCouponCodeField={onChangeCouponCodeField}
							onChangeFormFields={onChangeFormFields}
							onSubmit={onSubmit}
						/>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}

CoinbaseV3.propTypes = {
	i18n: PropTypes.object,
	mbg: PropTypes.bool,
	isApplicableInTheCountry: PropTypes.bool,
	upgradeMode: PropTypes.bool,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	currency: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.number,
	selectedPackage: PropTypes.object,
	selectedPaymentMethod: PropTypes.string,
	formFields: PropTypes.object,
	paymentFields: PropTypes.object,
	withPassword: PropTypes.bool,
	fieldsError: PropTypes.object,
	fieldsTouched: PropTypes.object,
	btnDisabled: PropTypes.bool,
	btnProcessing: PropTypes.bool,
	couponYearly: PropTypes.object,
	couponCode: PropTypes.string,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponMonthly: PropTypes.object,
	couponYearly2: PropTypes.object,
	isProxyDetected: PropTypes.bool,
	onChangePaymentMethod: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onInit: PropTypes.func,
	onSubmit: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(CoinbaseV3));